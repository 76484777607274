<template>
  <div id="order-view-action-apply-cancellations">
    <b-button
      id="order-view-actions-spec-cancelled-apply-cancellations"
      v-ripple.400
      variant="success"
      class="mb-75"
      block
      :disabled="disabled || quoteTouched"
      @click.prevent="confirm = true"
    >
      <feather-icon icon="CheckIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ $t('action.apply_cancellations') }} </span>
    </b-button>

    <b-sidebar id="order-view-action-apply-cancellations-confirm-sidebar" :visible="confirm" @change="confirm = $event">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('action.apply_cancellations') }} {{ $t('action.edition') }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <div class="p-2">
          <h4 class="mb-2">{{ $t('order.cancellation.apply_question') }}</h4>
          <b-button
            id="order-view-action-apply-cancellations-confirm-action-yes"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="applyCancellation(), hide()"
          >
            {{ $t('common.yes') }}
          </b-button>
          <b-button
            id="order-view-action-apply-cancellations-confirm-action-no"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="hide"
          >
            {{ $t('common.no') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteApplyCancellationPoliciesRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewActionApplyCancellations',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      confirm: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteTouched']),
    ...mapFields('quote', ['quoteEditable', 'quoteLoading', 'quoteSelected.quote.cancellationPolicyType']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    applyCancellation() {
      this.quoteLoading = true
      patchQuoteApplyCancellationPoliciesRequest(
        this.quoteSelected.quote.id,
        { cancellationPolicyType: this.cancellationPolicyType, apply: true },
        this.$store.getters['quote/quoteTitleAndId'],
      )
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteEditable = false
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>
